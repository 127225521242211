import { Transition, Variants } from 'framer-motion';

export const HEADER_HEIGHT = 78;

export const heightMobileBreakpoints = 576;

export const viewportBreakpoints = {
  mobile: `${heightMobileBreakpoints}px`,
  tablet: '768px',
  laptop: '992px',
  laptopL: '1200px',
  desktopS: '1366px',
  desktopM: '1600px',
  desktop1800: '1800px',
  desktopL: '2300px',
  desktop: '2560px',
};


export const pageTransitionVariants: Variants = {
  initial: {
    opacity: 0,
    y: -500,
  },
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
  },
};

export const pageAnimationParams: Transition = {
  duration: 0.5,
  type: 'tween',
  ease: 'anticipate',
};
