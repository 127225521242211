import { AnimatePresence } from 'framer-motion';
import { FC, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import AboutUs from 'modules/aboutUs/AboutUs';
import ContactUs from 'modules/contactUs/ContactUs';
import Homepage from 'modules/homepage/Homepage';
import Login from 'modules/login/Login';
import Solution from 'modules/solution/Solution';
import WhyUs from 'modules/whyUs/WhyUs';
import TermsOfUse from 'modules/privacy/TermsOfUse';
import PrivacyPolicy from 'modules/privacy/PrivacyPolicy';

import Layout from 'layout/Layout/Layout';

import { RouteTypes } from './types';

export const privateRoutes = [
  {
    name: 'About Us',
    route: RouteTypes.aboutUs,
    component: <Layout><AboutUs /></Layout>,
  },
  {
    name: 'Why Us',
    route: RouteTypes.whyUs,
    component: <Layout><WhyUs /></Layout>,
  },
  {
    name: 'Solution',
    route: RouteTypes.solution,
    component: <Layout><Solution /></Layout>,
  },
  {
    name: 'Contact',
    route: RouteTypes.contactUs,
    component: <Layout><ContactUs /></Layout>,
  },
  {
    route: RouteTypes.default,
    component: <Layout><Homepage /></Layout>,
  },
  {
    route: RouteTypes.login,
    component: <Layout><div style={{overflowX: 'hidden'}}><Login /></div></Layout>,
  },
];

const Router: FC = () => {
  const isAuth = true;
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <AnimatePresence>
      <Routes>
        {isAuth ? (
          <>
            {privateRoutes.map((el, i) => (
              <Route key={i} path={el.route} element={el.component} />
            ))}
            <Route path="/policies/terms-of-use" element={<TermsOfUse />}  />
            <Route path="/policies/privacy-policy" element={<PrivacyPolicy />}  />
          </>
        ) : (
          <>
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<Login />} />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
};

export default Router;
